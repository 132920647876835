<app-navbar></app-navbar>
<body class="body_container">
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
  Snackbar CSS Styles</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
  Snackbar CSS Styles</button>
<button id="succeeded_review" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededStartReview()">
  Snackbar CSS Styles</button>
<button id="failed_review" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedStartReview()">
  Snackbar CSS Styles</button>
<button id="refresh_group" class="snack_button" (click)="refreshGroup()"></button>
<button id="start_checking_jobs" class="snack_button" (click)="StartCheckingJobs()"></button>
<button id="refreshJobs" style="display: none" (click)="refreshJobs()"></button>
<div class="my-overlay" *ngIf="showOverlay">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>
<h1>
  <a class="black-box">
    <img src="assets/img/review_white.svg" alt="search_icon" class="logo-icon">
    Match
  </a>
  <a class="production">PRODUCTION</a>
<!--  <a class="development">DEVELOPMENT</a>-->
  <a class="right" *ngIf="is_search_ready"> Total results: {{total_searches}}</a>
</h1>
<table>
  <tr>
    <td>
      <div class="menu-div">
        <div class="button-div">
          <button (click)="selected_datatype('Company')" id="hidden-company" class="menu-button">See Current Company Results</button>
          <button (click)="selected_datatype('Person')" id="hidden-person" class="menu-button">See Current Person Results</button>
          <button (click)="startReview('Company')" id="start-matching-Company" class="menu-button" style="margin-left: 5px">Start Matching Company</button>
          <button (click)="startReview('Person')" id="start-matching-Person" class="menu-button">Start Matching Person</button>
        </div>
        <p class="check-jobs" (click)="showRunningJobs()">
          Check running jobs
          <img id="review-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
        </p>
      </div>
      <br>
        <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
        <div *ngIf="jobs">
          <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
          <p>There are {{jobs.length}} job(s) running</p>
          <div *ngFor="let job of jobs">
            <p>
              Job type: {{job.type}}
            </p>
            <p>
              Job state: {{job.state}}
            </p>
            <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete</button>
            <p>--------------------------------------------------------------</p>
          </div>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td>
        <p id="hidden-p" class="hidden-p">Your review has started. Please wait about 3-5 minutes before you check the results.</p>
    </td>
  </tr>
</table>
<p></p>
<div *ngIf="is_company_search">
  <p> Total groups: {{group_list_length}}  </p>
  <div *ngIf="selected_group">
    Do this action for all records within this group
    <mat-form-field appearance="fill">
      <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event.value)">
        <mat-option title="No action" value="None">None</mat-option>
        <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
        <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
        <mat-option title="Kick" value="Kick">Kick</mat-option>
<!--        <mat-option title="Delete" value="Delete">Delete</mat-option>-->
        <mat-option title="Edit fields" value="Edit">Edit</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0">Proceed Manually</button>
    <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
<!--    <button class="button" (click)="exportexcel('CompaniesMatchResults.xlsx', 'company')">Download To Excel</button>-->
  </div>
  <div *ngIf="is_long_list">
    <div class="scrollable">
      <table class="in-table">
        <tr>
          <td *ngFor="let group of group_list" id="company-group-{{group}}" class="table-group" (click)="Group_Data(group)">
            Group <br> {{group}}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="!is_long_list">
    <div class="single-nonscrollable">
      <table class="in-table">
        <tr>
          <td class="single-table-group" id="company-group-1" (click)="Group_Data(group_list[0])">
            Group <br> {{group_list[0]}}
          </td>
          <td class="table-group">
            <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="fixed-table">
    <div class="fixed-width-table-scroll-y">
      <table class="table_header">
        <tr>
          <td>
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource"  id="excel-table-company">
                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
                  <td mat-cell *matCellDef="let element" class="td-dropdown" id="company-field-{{element.actionType}}">
                    <mat-form-field appearance="fill" class="mat-dropdown" id="company-select-{{element.winPurePrimK}}">
                      <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                        <mat-option title="No action" value="None" id="company-none">None</mat-option>
                        <mat-option title="Add to main database" value="AddToMainDatabase" id="company-select">Select</mat-option>
                        <mat-option title="Merge to selected" value="Merge" id="company-merge">Merge</mat-option>
                        <mat-option title="Kick" value="Kick" id="company-change">Kick</mat-option>
                        <mat-option title="MigratePeople" value="MigratePeople" id="MigratePeople">Migrate People</mat-option>
<!--                        <mat-option title="Delete" value="Delete" id="company-delete">Delete</mat-option>-->
                        <mat-option title="Edit fields" value="Edit" id="company-edit">Edit</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="CompanyId">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Company id</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" (click)="LinkTo(element.companyId)" class="pointer">
                    {{element.companyId}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="SourceName">
                  <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element['source name'] == 'Company'" >
                      ceo360 DB
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="CompanyName">
                  <th mat-header-cell *matHeaderCellDef class="table_header">Company Name</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.companyName}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" name="companyName" [(ngModel)]="element.companyName" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Adress1">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Address1</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.address1}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" name="address1" [(ngModel)]="element.address1" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Adress2">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Address2</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.address2}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" name="address2" [(ngModel)]="element.address2" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="City">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> City</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.city}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" name="city" [(ngModel)]="element.city" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="State">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> State</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.state}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'">
                      <select class="dropdown-address" [(ngModel)]="element.state">
                        <option value="{{not_found_state}}">{{not_found_state}}</option>
                        <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                          {{state.stateName}}
                        </option>
                      </select>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Zip">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Zip</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.zip}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="zip" name="zip" [(ngModel)]="element.zip" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Country">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Country</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.country}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'">
                      <select class="dropdown-address" [(ngModel)]="element.country">
                        <option value="{{not_found_country}}">{{not_found_country}}</option>
                        <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                          {{country.countryName}}
                        </option>
                      </select>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Phone">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Phone</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.phone}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="phone" name="phone" [(ngModel)]="element.phone" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Website">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.website}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="website" name="website" [(ngModel)]="element.website" class="edit-fields">
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="CIK ID">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> CIK ID </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.cikId}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text"  name="cikId" [(ngModel)]="element.cikId" class="edit-fields">
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Ticker">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.ticker}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="company-ticker" name="company-ticker" [(ngModel)]="element.ticker" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Annual Revenue">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.annualRevenue}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="annualRevenue" name="annualRevenue" [(ngModel)]="element.annualRevenue" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Verified Date">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      <div *ngIf="element.verifiedDate; else lastupdated">
                        {{element.verifiedDate}}
                      </div>
                      <ng-template #lastupdated>
                        {{element.lastUpdated}}
                      </ng-template>
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                        <input type="text" name="person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                      </div>
                      <ng-template #lastupdatedEdit>
                        <input type="text" name="company-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                      </ng-template>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Active Status">
                  <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.activeStatus}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'">
                      <select class="dropdown-address" [(ngModel)]="element.activeStatus">
                        <option value="Not Found">Not Found</option>
                        <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                          {{status}}
                        </option>
                      </select>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="AddressSource">
                  <th mat-header-cell *matHeaderCellDef class="table_header"> AddressSource </th>
                  <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                    <div *ngIf="element.actionType!='Edit'">
                      {{element.addressSource}}
                    </div>
                    <div *ngIf="element.actionType=='Edit'" class="input-box">
                      <input type="text" id="addressSource" name="addressSource" [(ngModel)]="element.addressSource" class="edit-fields">
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
              </table>
              <!--          <mat-paginator [pageSizeOptions]="[20, 25, 30]" showFirstLastButtons></mat-paginator>-->
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
<!--  <div *ngIf="selected_group">-->
<!--    Do this action for all records within this group-->
<!--    <mat-form-field appearance="fill" id="all-select-form">-->
<!--      <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event.value)">-->
<!--        <mat-option title="No action" value="None" id="all-company-none">None</mat-option>-->
<!--        <mat-option title="Add to main database" value="Select" id="all-company-select">Select</mat-option>-->
<!--        <mat-option title="Merge to selected" value="Merge" id="all-company-merge">Merge</mat-option>-->
<!--        <mat-option title="Remove from group" value="Change" id="all-company-change">Kick</mat-option>-->
<!--        <mat-option title="Delete" value="Delete" id="all-company-delete">Delete</mat-option>-->
<!--        <mat-option title="Edit fields" value="Edit" id="all-company-edit">Edit</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <div *ngIf="selected_group">-->
<!--    Do this action for all records within this group-->
<!--    <mat-form-field appearance="fill">-->
<!--      <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event.value)">-->
<!--        <mat-option title="No action" value="None">None</mat-option>-->
<!--        <mat-option title="Add to main database" value="Select">Select</mat-option>-->
<!--        <mat-option title="Merge to selected" value="Merge">Merge</mat-option>-->
<!--        <mat-option title="Remove from group" value="Change">Kick</mat-option>-->
<!--        <mat-option title="Delete" value="Delete">Delete</mat-option>-->
<!--        <mat-option title="Edit fields" value="Edit">Edit</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <p></p>-->
<!--  <div *ngIf="are_actionButtons_visible > 0">-->
<!--    Please, select merge rules to continue-->
<!--    <label>-->
<!--      <select [(ngModel)]="merge_rule" class="dropdown">-->
<!--        <option>UpdateEmptyField</option>-->
<!--        <option>UpdateAllField</option>-->
<!--      </select>-->
<!--    </label>-->
<!--    <button class="button" (click)="VerifyBulkActions()">Proceed</button>-->
<!--    <button class="button" (click)="ManualMerge()">ManualMerge</button>-->
<!--  </div>-->
<!--  <p></p>-->
</div>
<div *ngIf="is_person_search">
  <div *ngIf="!group_list">
    No data available
  </div>
  <div *ngIf="group_list">
    <p> Total groups: {{group_list_length}}  </p>
    <div *ngIf="selected_group">
      Do this action for all records within this group
      <mat-form-field appearance="fill">
        <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event.value)" id="test">
          <mat-option title="No action" value="None">None</mat-option>
          <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
          <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
          <mat-option title="Kick" value="Kick">Kick</mat-option>
<!--          <mat-option title="Delete" value="Delete">Delete</mat-option>-->
          <mat-option title="Edit fields" value="Edit">Edit</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0">Proceed Manually</button>
      <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
<!--      <button class="button" (click)="exportexcel('PeopleMatchResults.xlsx', 'person')">Download To Excel</button>-->
    </div>
    <div *ngIf="is_long_list">
      <div class="scrollable">
        <table class="in-table">
          <tr>
            <td id="id" *ngFor="let group of group_list" id="person-group-{{group}}" class="table-group" (click)="Group_Data(group)">
              Group <br> {{group}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="!is_long_list">
      <div class="single-nonscrollable">
        <table class="in-table">
          <tr>
            <td class="single-table-group" id="person-group-1" (click)="Group_Data(group_list[0])">
              Group <br> {{group_list[0]}}
            </td>
            <td class="table-group">
              <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="fixed-table">
      <div class="fixed-width-table-scroll-y">
        <table class="table_header">
          <tr>
            <td>
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" id="excel-table-person">
                  <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Action</th>
                    <td mat-cell *matCellDef="let element" class="td-dropdown" id="person-field-{{element.actionType}}">
                      <mat-form-field appearance="fill" class="mat-dropdown" id="person-select-{{element.winPurePrimK}}">
                        <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                          <mat-option title="No action" value="None" id="person-none">None</mat-option>
                          <mat-option title="Add to main database" value="AddToMainDatabase" id="person-select">Select</mat-option>
                          <mat-option title="Merge to selected" value="Merge" id="person-merge">Merge</mat-option>
                          <mat-option title="Kick" value="Kick" id="person-change">Kick</mat-option>
<!--                          <mat-option title="Delete" value="Delete" id="person-delete">Delete</mat-option>-->
                          <mat-option title="Edit fields" value="Edit" id="person-edit">Edit</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Person Id">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" (click)="LinkTo(element.personId)" class="pointer">
                      {{element.personId}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="SourceName">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element['source name'] == 'Person'" >
                        ceo360 DB
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="First Name">
                    <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.firstName}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-firstname" name="person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Middle Name">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Middle Name</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.middleName}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-middlename" name="person-middlename" [(ngModel)]="element.middleName" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Last Name">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.lastName}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-lastname" name="person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Suffix">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Suffix</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.suffix}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-suffix" name="person-suffix" [(ngModel)]="element.suffix" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Title">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.title}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-title" name="person-title" [(ngModel)]="element.title" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.email}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-email" name="person-email" [(ngModel)]="element.email" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="AltEmail">
                    <th mat-header-cell *matHeaderCellDef class="table_header">AltEmail</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.altEmail}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-altemail" name="person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Direct Phone">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.directPhone}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-directphone" name="person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Mobile Phone">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.mobilePhone}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-mobilephone" name="person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Primary Company">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      {{element.primaryCompany}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Board Company Associations">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Board Company Assoc.</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      {{element.boardCompanyName}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Ticker">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      {{element.ticker}}
                      <!--                <div *ngIf="element.actionType!='Edit'">-->
                      <!--                  {{element.ticker}}-->
                      <!--                </div>-->
                      <!--                <div *ngIf="element.actionType=='Edit'">-->
                      <!--                  <input type="text" id="person-ticker" name="person-ticker" [(ngModel)]="element.ticker" class="edit-fields">-->
                      <!--                </div>-->
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Shipping Add1">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.primaryAddress1}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-primaryaddress1" name="person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Shipping Add2">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.primaryAddress2}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-primaryaddress2" name="person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Shipping City">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      {{element.primaryCity}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Shipping State">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.primaryState}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" >
                        <select class="dropdown-address" [(ngModel)]="element.primaryState">
                          <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                          <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                            {{state.stateName}}
                          </option>
                        </select>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Shipping Zipcode">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.primaryZip}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-primaryzipcode" name="person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Shipping Country">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.primaryCountry}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'">
                        <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                          <option value="{{not_found_country}}">{{not_found_country}}</option>
                          <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                            {{country.countryName}}
                          </option>
                        </select>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Alternate Address Source">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Source</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.alternateAddressSource}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-alternateAddressSource" name="person-alternateAddressSource" [(ngModel)]="element.alternateAddressSource" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Alternate Address Preferred">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.alternateAddressPreferred}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <input type="text" id="person-alternateAddressPreferred" name="person-alternateAddressPreferred" [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Alternate Address Type">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.alternateAddressType}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'"class="input-box">
                        <input type="text" id="person-alternateAddressType" name="person-alternateAddressType" [(ngModel)]="element.alternateAddressType" class="edit-fields">
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Verified Date">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Verified Date</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        <div *ngIf="element.verifiedDate; else lastupdated">
                          {{element.verifiedDate}}
                        </div>
                        <ng-template #lastupdated>
                          {{element.lastUpdated}}
                        </ng-template>
                      </div>
                      <div *ngIf="element.actionType=='Edit'" class="input-box">
                        <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                          <input type="text" id="person-verifieddate" name="person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                        </div>
                        <ng-template #lastupdatedEdit>
                          <input type="text" id="person-lastupdated" name="person-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                        </ng-template>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Active Status">
                    <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                    <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                      <div *ngIf="element.actionType!='Edit'">
                        {{element.activeStatus}}
                      </div>
                      <div *ngIf="element.actionType=='Edit'">
                        <select class="table-select" [(ngModel)]="element.activeStatus">
                          <option value="Not Found">Not Found</option>
                          <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                            {{status}}
                          </option>
                        </select>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
                </table>
                <!--            <mat-paginator [pageSizeOptions]="[20, 25, 30]" showFirstLastButtons></mat-paginator>-->
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
<!--  <div *ngIf="selected_group">-->
<!--    Do this action for all records within this group-->
<!--    <mat-form-field appearance="fill">-->
<!--      <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event.value)" id="test">-->
<!--        <mat-option title="No action" value="None">None</mat-option>-->
<!--        <mat-option title="Add to main database" value="Select">Select</mat-option>-->
<!--        <mat-option title="Merge to selected" value="Merge">Merge</mat-option>-->
<!--        <mat-option title="Remove from group" value="Change">Kick</mat-option>-->
<!--        <mat-option title="Delete" value="Delete">Delete</mat-option>-->
<!--        <mat-option title="Edit fields" value="Edit">Edit</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <p></p>-->
<!--  <div *ngIf="are_actionButtons_visible > 0">-->
<!--    Please, select merge rules to continue-->
<!--    <label>-->
<!--      <select [(ngModel)]="merge_rule" class="dropdown">-->
<!--        <option>UpdateEmptyField</option>-->
<!--        <option>UpdateAllField</option>-->
<!--      </select>-->
<!--    </label>-->
<!--    <button class="button" (click)="VerifyBulkActions()">Proceed</button>-->
<!--    <button class="button" (click)="ManualMerge()">ManualMerge</button>-->
<!--  </div>-->
<!--  <p></p>-->
</div>
</body>
