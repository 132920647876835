<app-navbar></app-navbar>

<body>
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
  Snackbar CSS Styles</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
  Snackbar CSS Styles</button>
  <h1>
   <a class="black-box">
    <img src="assets/img/quarantine_settings_white.svg" alt="search_icon" class="icon">
    Stage Settings
    </a>
    <a class="production">PRODUCTION</a>
<!--    <a class="development">DEVELOPMENT</a>-->
  </h1>
  <table>
    <tr class="datatypes">
      <td>
        Data Type:
        <label>
          <input type="radio" name="datatype" value="Company" (change)="select_dataType($event)">
          <i>Company</i>
        </label>
        <label>
          <input type="radio" name="datatype" value="Person" (change)="select_dataType($event)">
          <i>Person</i>
        </label>
      </td>
    </tr>
  </table>

  <p></p>
  <p *ngIf="!is_Group_ready">Please select a datatype!</p>

  <table *ngIf="is_Group_ready" cellspacing="0" cellpadding="0" class="advanced_search">
    <tr>
      <td class="checklist">
        <p *ngFor="let field of settings_fields">
          <label *ngIf="!hiddenSettingsFields.includes(field.FieldName)" for="checkbox-{{field.FieldName}}">
            <input type="checkbox" id="checkbox-{{field.FieldName}}" name="{{field.FieldName}}" value="{{field.FieldName}}"
                   (change)="checked_values($event)" class="regular-checkbox">
            {{getDisplayedName(field.FieldName)}}
          </label>
        </p>
      </td>
      <td *ngIf="is_Group_ready">
        <table class="in-table">
          <tr>
            <th>Group</th>
            <th>Exact <br> match</th>
            <th>Fuzzy <br> level</th>
            <th>Ignore <br> Null values</th>
            <th>Knowledge <br> base Library</th>
            <th>Group <br>Level</th>
            <th>Weight<br> in group</th>
          </tr>
          <tr *ngFor="let group of match_group">
            <td colspan="7">
              <table *ngFor="let groups of group.Groups " class="in-table">
                <tr class="list-header">
                  <td class="group">
                      <label for="selected-group-{{groups.GroupId}}" style="display: block;">
                      <input type="radio" id="selected-group-{{groups.GroupId}}" name="selected-group" value="{{groups.GroupId}}" (change)="Select_group($event)">
                      Setting {{groups.GroupId}}
                        <img src="assets/img/remove_field.svg" class="remove-group" (click)="RemoveGroup(groups.GroupId)">
                      </label>
                  </td>
                </tr>
                <tr class="select_row" *ngFor="let condition of groups.Conditions">
                    <div>
                      <table class="in-table">
                        <tr>
                          <td class="alt">
                            {{getDisplayedName(condition.Fields[0].ColumnName)}}
                          </td>
                          <td class="alt">
                            <label for="checked-{{condition.Fields[0].ColumnName}}">
                              <input id="checked-{{condition.Fields[0].ColumnName}}" type="checkbox"
                                     (click)="construct_ExactMatch(condition.Fields[0].ColumnName, groups.GroupId)"  class="input-checkbox" [checked]="isExactMatch(condition.Fields[0].ColumnName)" [disabled]="isExactMatch(condition.Fields[0].ColumnName)">
                            </label>
                          </td>
                          <td class="alt">
                            <label for="Level-{{condition.Fields[0].ColumnName}}">
                              <input id="Level-{{condition.Fields[0].ColumnName}}" (change)="construct_Fuzzy($event, condition.Fields[0].ColumnName, groups.GroupId)" class="input-number" [value]="condition.Level*100" [disabled]="isExactMatch(condition.Fields[0].ColumnName)" [ngClass]="isExactMatch(condition.Fields[0].ColumnName) ? 'background-disabled': ''">
                            </label>
                          </td>
                          <td class="alt">
                            <label for="IgnoreNullValues-{{condition.Fields[0].ColumnName}}">
                              {{condition.IgnoreNullValues}}
                              <select id="IgnoreNullValues-{{condition.Fields[0].ColumnName}}" class="dropdown" value="{{condition.IgnoreNullValues}}" (change)="nullVal($event, condition.Fields[0].ColumnName, groups.GroupId)">
                                <option>true</option>
                                <option>false</option>
                              </select>
                            </label>
                          </td>
                          <td class="alt">
                            <label for="DictionaryType-{{condition.Fields[0].ColumnName}}">
                              {{condition.DictionaryType}}
                              <select id="DictionaryType-{{condition.Fields[0].ColumnName}}" class="dropdown" value="{{condition.DictionaryType}}" (change)="construct_DictionaryType($event, condition.Fields[0].ColumnName, groups.GroupId)">
                                <option *ngFor="let dict of dictionary" [defaultSelected]="">{{dict}}</option>
                              </select>
                            </label>
                          </td>
                          <td class="alt">
                            <label>
                              <input (change)="construct_GroupLevel($event, groups.GroupId)" [value]="groups.GroupLevel*100" class="input-number">
                            </label>
                          </td>
                          <td class="alt">
                            <label for="Weight-{{condition.Fields[0].ColumnName}}">
                              <input id="Weight-{{condition.Fields[0].ColumnName}}" (change)="construct_WeightInGroup($event, condition.Fields[0].ColumnName, groups.GroupId)" class="input-number" [value]="condition.Weight*100">
                            </label>
                          </td>
                        </tr>
                      </table>
                    </div>
                </tr>
              </table>
            </td>
          </tr>
          <tr *ngIf="is_dataType_selected" (click)="AddGroup()">
            <td colspan="7" class="addGroup_button" *ngIf="is_Group_ready">
              <a>+ Add Setting</a>
            </td>
          </tr>
          <tr *ngIf="is_dataType_selected">
            <td colspan="7" *ngIf="is_Group_ready">
              <button class="simple-button" (click)="Send()">Save</button>
            </td>
          </tr>
          <tr *ngIf="is_Engagement">
            <td colspan="7">
              No available data at the moment!
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
